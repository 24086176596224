<template>
  <div class="dashboard-lab-services">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <lab-menu></lab-menu>
            <div class="column is-12 right-column">
              <p class="pb-4">Elije los productos que produce tu laboratorio. Recuerda ingresar todos los valores requeridos y el producto se activará automáticamente</p>
              <div id="search-products" class="column is-12">
                <h3 class="title is-3 has-text-centered">Buscar por producto</h3>
                <div class="control has-icons-right">
                  <input class="input is-rounded search-input" type="text" placeholder="Search a service (e.g. full protesis, corona implant…)" v-model="searchText" @input="searchProduct()">
                  <span class="icon is-right has-text-centered">
                    <span class="icon-search"></span>
                  </span>
                  <p class="pt-3 has-text-centered">{{products.length}} resultados encontrados</p>
                </div>
              </div>
              <!-- Implantes -->
              <template v-for="category in categories">
                <div class="product-type mb-4" v-bind:key="category.id">
                  <!-- <p><strong class="txt-yellow is-uppercase"></strong></p> -->
                  <button class="button is-primary is-fullwidth is-medium mt-4 mb-4 accordion-btn" @click="toggleAccordion(category)" :disabled="productsByCategory(category.id).length === 0">
                    <span class="name">{{category.name}}</span>
                    <span class="icon icon-filters-open" v-if="productsByCategory(category.id).length !== 0 && !category.isOpen"></span>
                    <span class="icon icon-filters-close" v-if="productsByCategory(category.id).length !== 0 && category.isOpen"></span>
                  </button>
                  <div class="columns mb-3 is-multiline is-mobile no-products" v-if="productsByCategory(category.id).length === 0">
                    <div class="column is-8">
                      <p class="has-text-weight-bold pt-3">
                        No hay productos disponibles
                      </p>
                    </div>
                  </div>
                  <template v-for="(product) in productsByCategory(category.id)">
                    <product-offer v-bind:key="product.id"
                      v-if="category.isOpen"
                      v-on:create-product-offer="createProductOffer"
                      v-on:update-product-offer="updateProductOffer"
                      v-on:delete-product-offer="deleteProductOffer"
                      :product="product"
                      :product-offer="getProductOffer(product.id)"></product-offer>
                  </template>
                </div>
              </template>

            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import LabMenu from '@/components/Laboratory/LabMenu.vue'
import ProductOffer from '@/components/Laboratory/ProductOffer.vue'
import { IsEmptyOrNull } from '@/utils/utils.js'

export default {
  name: 'LaboratoryLabServices',
  components: {
    HeroImage,
    Footer,
    LabMenu,
    ProductOffer
  },
  data () {
    return {
      productOffers: [],
      products: [],
      materials: [],
      categories: [],
      email: '',
      searchText: '',
      searchTimeout: null,
      searchIsLoading: false
    }
  },
  computed: {
    ...mapGetters({
      laboratory: 'user/laboratory'
    })
  },
  watch: {
    'materials' (newVal) {
      if (newVal.length > 0 && this.categories.length > 0) {
        this.getProductOffersByLab()
      }
    },
    'categories' (newVal) {
      if (newVal.length > 0 && this.materials.length > 0) {
        this.getProductOffersByLab()
      }
    }
  },
  methods: {
    logout () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    toggleAccordion (category) {
      category.isOpen = !category.isOpen
      console.log(category.isOpen)
    },
    createProductOffer (newProductOffer) {
      const productOffer = this.getProductOfferByProduct(newProductOffer.product_id)
      if (IsEmptyOrNull(productOffer)) {
        console.log('createProductOffer')
        console.log(newProductOffer)
        this.addProductOffer(newProductOffer)
      } else {
        if (productOffer.isDeleted) {
          console.log('Remove delete')
          productOffer.isDeleted = false
        }
      }
    },
    updateProductOffer (updatedProductOffer) {
      for (let i = 0; i < this.productOffers.length; i++) {
        if (this.productOffers[i].product_id === updatedProductOffer.product_id) {
          this.productOffers[i].price = updatedProductOffer.price
          this.productOffers[i].min_days_to_deliver = updatedProductOffer.min_days_to_deliver
          this.productOffers[i].max_days_to_deliver = updatedProductOffer.max_days_to_deliver
          this.productOffers[i].description = updatedProductOffer.description
          this.productOffers[i].warranty = updatedProductOffer.warranty
          this.productOffers[i].material = updatedProductOffer.material

          this.productOffers[i].isUpdated = true
          this.updateProductOffers(this.productOffers[i])
        }
      }
    },
    deleteProductOffer (productId) {
      for (let i = 0; i < this.productOffers.length; i++) {
        if (this.productOffers[i].product_id === productId) {
          this.productOffers[i].isDeleted = true
          this.deleteProductOffers(this.productOffers[i].id, i)
          break
        }
      }
    },
    getProductOffer (productId) {
      let offer = {
        id: null,
        laboratory_id: null,
        max_days_to_deliver: 0,
        min_days_to_deliver: 0,
        price: 0,
        product_id: null
      }
      for (let i = 0; i < this.productOffers.length; i++) {
        if (this.productOffers[i].product_id === productId) {
          offer = this.productOffers[i]
          break
        }
      }
      return offer
    },
    getProductOfferByProduct (productId) {
      let productOffer = null
      for (let i = 0; i < this.productOffers.length; i++) {
        if (this.productOffers[i].product_id === productId) {
          productOffer = this.productOffers[i]
          // productOffer.index = i
          break
        }
      }
      return productOffer
    },
    addProductOffer (offer) {
      const self = this
      this.$store.dispatch('products/createProductOffers', {
        labId: this.laboratory.id,
        offer: offer,
        cb: (response) => {
          console.log('OFFER ADDED')
          console.log(response)
          self.productOffers.push(response)
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    updateProductOffers (offer) {
      this.$store.dispatch('products/updateProductOffers', {
        offer: offer,
        cb: (response) => {
          console.log('OFFER UPDATED')
          console.log(response)
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    deleteProductOffers (productOfferId, index) {
      const self = this
      this.$store.dispatch('products/deleteProductOffers', {
        productOfferId: productOfferId,
        cb: (response) => {
          console.log('OFFER DELETED')
          console.log(response)
          self.productOffers.splice(index, 1)
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    productsByCategory (categoryId) {
      return this.products.filter(function (product) {
        return product.category_id === categoryId
      })
    },
    getCategoryName (categoryId) {
      const result = this.categories.find(({ id }) => id === categoryId)
      return result.name
    },
    getMaterialName (materialId) {
      const result = this.materials.find(({ id }) => id === materialId)
      return result.name
    },
    getProductOffersByLab () {
      const self = this
      const payload = {
        labId: this.laboratory.id,
        page: 1,
        priceIn: '',
        minDaysToDeliverIn: '',
        maxDaysToDeliverIn: '',
        productIdIn: '',
        pageSize: 1000,
        cb: (response) => {
          self.productOffers = response
          self.fetchProducts()
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      }
      this.$store.dispatch('lab/getProductOfferByLab', payload)
    },
    searchProduct () {
      const self = this
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        self.fetchProducts()
      }, 500)
      if (this.searchText.trim().length > 1) {
        this.searchIsLoading = true
      } else {
        this.searchIsLoading = false
      }
    },
    fetchProducts () {
      const self = this
      const payload = {
        page: 1,
        pageSize: 1000,
        searchString: this.searchText,
        cb: (response) => {
          self.products = response
        },
        cbError: (error) => {
          console.log(error)
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      }
      this.$store.dispatch('products/getProducts', payload)
    }
  },
  created () {
    const self = this
    this.$store.dispatch('products/getProductCategories', {
      cb: (response) => {
        for (let i = 0; i < response.length; i++) {
          response[i].products = []
          self.$set(response[i], 'isOpen', false)
        }
        self.categories = response
      },
      cbError: (error) => {
        self.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: error.response.data.error
          }
        })
      }
    })

    this.$store.dispatch('products/getProductMaterials', {
      cb: (response) => {
        self.materials = response
      },
      cbError: (error) => {
        self.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: error.response.data.error
          }
        })
      }
    })
  }
}
</script>

<style scoped lang="scss">
.dashboard-lab-services{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -430px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        padding: 0px;
        flex-direction: column;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .icon-search{
          display: block;
          height: 34px;
          width: 34px;
          margin-right: 3px;
          margin-top: 3px;
          background-color: #E3914F;
          border-radius: 50%;
          line-height: 34px;
          cursor: pointer;
          color: #FFF;
        }
        .search-input{
          border: 1px solid #57567C;
        }
        .accordion-btn{
          text-align: left;
          justify-content: space-between;
          .icon{
            position: relative;
            top: 4px;
            font-size: 1.4rem;
          }
        }
        .right-column{
          padding: 80px;
          padding-top: 40px;
          .gallery-img-wrap{
            width: 100%;
            height: 90px;
            img{
              width: 100%;
              border-radius: 8px;
            }
            &.empty{
              button{
                width: 100%;
                height: 100%;
                background-color: transparent;
                border-radius: 8px;
                border: 3px solid #F6F4EF;
                span{
                  color: #F6F4EF;
                  font-size: 2.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <p class="control textarea-wrap" v-bind:class="{'has-focus': hasFocus}">
    <span class="placeholder" v-html="placeholder"></span>
    <textarea class="textarea has-fixed-size is-medium is-primary"
      :name="name"
      :value="value"
      :disabled="disabled"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"></textarea>
  </p>
</template>

<script>
import { IsEmptyOrNull } from '@/utils/utils'
export default {
  name: 'InputText',
  props: {
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    name: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasFocus: false
    }
  },
  methods: {
    onInput (event) {
      // Can add validation here
      this.$emit('input', event.target.value)
      // On component add v-on:input="functionHERE" to listen to this emit
    },
    onFocus () {
      this.hasFocus = true
    },
    onBlur (event) {
      if (IsEmptyOrNull(event.target.value)) {
        this.hasFocus = false
      }
    }
    /* onChange(event) { // Supports .lazy
      // Can add validation here
      this.$emit('change', event.target.value);
    } */
  },
  created () {
    if (!IsEmptyOrNull(this.value)) {
      this.hasFocus = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.textarea-wrap{
  .placeholder{
    position: absolute;
    top: 14px;
    left: 14px;
    z-index: 999;
    font-weight: bold;
    transition: all .3s ease-out;
  }
  &.has-focus{
    .placeholder{
      font-size: .9rem;
      top: 6px;
    }
  }
  .textarea{
    padding-top: 18px;
  }
}
</style>

<template>
  <div class="column is-12 sidebar-menu">
    <div class="sidebar has-background-light">
      <div class="title-wrap">
        <h2 class="title is-3 txt-c-mobile">{{current.label}}</h2>
      </div>
      <div class="buttons-wrap">
        <button class="sidebar-btn" @click="goTo(labInfo.url)" v-bind:class="{'selected': current.url.includes(labInfo.url) }">
          <div class="sidebar-btn-icon"><span class="icon-info"></span></div>
        </button>
        <button class="sidebar-btn" @click="goTo(products.url)" v-bind:class="{'selected': current.url.includes(products.url) }">
          <div class="sidebar-btn-icon"><span class="icon-tooth"></span></div>
        </button>
        <button class="sidebar-btn" @click="goTo(orders.url)" v-bind:class="{'selected': current.url.includes(orders.url) }">
          <div class="sidebar-btn-icon"><span class="icon-orders"></span></div>
        </button>
        <button class="sidebar-btn" @click="goTo(payments.url)" v-bind:class="{'selected': current.url.includes(payments.url) }">
          <div class="sidebar-btn-icon"><span class="icon-payments"></span></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LabMenu',
  props: {},
  data () {
    return {
      labInfo: {
        label: 'Info del laboratorio',
        url: '/laboratory/info-laboratory'
      },
      products: {
        label: 'Tus productos',
        url: '/laboratory/products'
      },
      orders: {
        label: 'Pedidos',
        url: '/laboratory/orders'
      },
      payments: {
        label: 'Detalles de pago',
        url: '/laboratory/payment-details'
      },
      current: {
        label: '',
        url: ''
      }
    }
  },
  watch: {
    $route () {
      this.setCurrentStep()
    }
  },
  methods: {
    goTo (destination) {
      this.$router.push(destination)
    },
    setCurrentStep () {
      if (this.$route.path.includes(this.labInfo.url)) {
        this.current = this.labInfo
      }
      if (this.$route.path.includes(this.products.url)) {
        this.current = this.products
      }
      if (this.$route.path.includes(this.orders.url)) {
        this.current = this.orders
      }
      if (this.$route.path.includes(this.payments.url)) {
        this.current = this.payments
      }
    }
  },
  created () {
    this.setCurrentStep()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sidebar-menu{
  height: 80px;
  padding-top: 0px;
  padding-bottom: 0px;
  .sidebar{
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 80px;
    .title-wrap{
      display: flex;
      justify-content: flex-start;
      h2{
        align-self: center;
      }
    }
    .buttons-wrap{
      display: flex;
      justify-content: flex-end;
      .sidebar-btn{
        cursor: pointer;
        position: relative;
        width: 60px;
        height: 60px;
        border: 0px;
        margin-right: 14px;
        background-color: transparent;
        align-self: center;
        &.selected{
          .sidebar-btn-icon{
            background-color: #E3914F;
            span{
              color: #FFF;
            }
          }
        }
        .sidebar-btn-icon{
          position: relative;
          height: 60px;
          width: 60px;
          background-color: #FFF;
          border-radius: 50%;
          span{
            color: #57567C;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.7rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .sidebar-menu{
    position: absolute;
    top: -50px;
    left: 0px;
    width: 100%;
    background-color: transparent;
    .sidebar{
      display: flex;
      padding: 0px;
      .sidebar-btn{
        width: 25%;
        .sidebar-btn-icon{
          box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
          margin: 0 auto;
        }
      }
    }
  }
}
</style>

<template>
  <div class="columns mb-3 is-multiline is-mobile product-offer-wrap">
    <div class="column is-8">
      <p class="has-text-weight-bold pt-3 is-size-5 txt-yellow">
        {{product.name}}
      </p>
    </div>
    <div class="column is-4">
      <div class="custom-switch float-r w-auto  pt-2">
        <input type="checkbox" :id="'switch-' + product.id" v-model="toggle" /><label class="toggle" :for="'switch-' + product.id">Toggle</label>
      </div>
    </div>
    <div class="column is-5 is-full-mobile" v-if="toggle">
      <p class="has-text-weight-bold pb-3">TU PRECIO NETO EN EUROS*</p>
      <div class="columns is-mobile">
        <div class="column is-6 is-full-mobile">
          <div class="field">
            <input-text :type="'number'" :placeholder="'Precio*'" v-model="price" :name="'price'" v-on:input="updateProduct"></input-text>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-5 is-full-mobile" v-if="toggle && !product.extra">
      <p class="has-text-weight-bold pb-3">DÍAS ESTIMADOS PARA LA ENTREGA*</p>
      <div class="columns is-mobile">
        <div class="column is-6">
          <div class="field">
            <input-text :type="'number'" :placeholder="'Días min*'" v-model="min_days_to_deliver" :name="'min_days_to_deliver'" v-on:input="updateProduct"></input-text>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <input-text :type="'number'" :placeholder="'Días máx*'" v-model="max_days_to_deliver" :name="'max_days_to_deliver'" v-on:input="updateProduct"></input-text>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-12" v-if="toggle && !product.extra">
      <input-text :placeholder="'Garantía'" v-model="warranty" :name="'warranty'" v-on:input="updateProduct"></input-text>
    </div>
    <div class="column is-12" v-if="toggle && !product.extra">
      <input-text :placeholder="'Material'" v-model="material" :name="'material'" v-on:input="updateProduct"></input-text>
    </div>
    <div class="column is-12" v-if="toggle">
      <text-area :placeholder="'Descripción'" v-model="description" :name="'description'" v-on:input="updateProduct"></text-area>
    </div>
    <div class="column is-12 pt-0 pb-0">
      <hr/>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/FormElements/InputText.vue'
import TextArea from '@/components/FormElements/TextArea.vue'

export default {
  name: 'ProductOffer',
  components: {
    InputText,
    TextArea
  },
  props: {
    product: Object,
    productOffer: Object
  },
  data () {
    return {
      price: '0',
      min_days_to_deliver: '0',
      max_days_to_deliver: '0',
      description: '',
      material: '',
      warranty: '',
      toggle: false,
      updateTimeout: null,
      timeoutDelay: 800
    }
  },
  watch: {
    'toggle' (newVal) {
      if (newVal) {
        const newOffer = {
          product_id: this.product.id,
          price: this.price * 100,
          min_days_to_deliver: this.min_days_to_deliver,
          max_days_to_deliver: this.max_days_to_deliver,
          description: this.description,
          warranty: this.warranty,
          material: this.material,
          isNew: true
        }
        // new product offer
        this.$emit('create-product-offer', newOffer)
      } else {
        // delete product offer
        this.$emit('delete-product-offer', this.product.id)
      }
    }
  },
  methods: {
    updateProduct () {
      const self = this
      clearTimeout(this.updateTimeout)
      this.updateTimeout = setTimeout(() => {
        const offerUpdated = {
          product_id: self.product.id,
          price: self.price * 100,
          min_days_to_deliver: self.min_days_to_deliver,
          max_days_to_deliver: self.max_days_to_deliver,
          description: self.description,
          warranty: self.warranty,
          material: self.material
        }
        self.$emit('update-product-offer', offerUpdated)
      }, this.timeoutDelay)
    }
  },
  created () {
    this.price = (this.productOffer.price / 100).toString()
    this.min_days_to_deliver = this.productOffer.min_days_to_deliver.toString()
    this.max_days_to_deliver = this.productOffer.max_days_to_deliver.toString()
    this.description = this.productOffer.description
    this.warranty = this.productOffer.warranty
    this.material = this.productOffer.material
    this.toggle = this.productOffer.id !== null
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  hr{
    opacity: .5;
    padding: 0.5px 0!important;
    margin-top: 7px!important;
    margin-bottom: 7px!important;
  }
</style>
